import mergeProps from "merge-props";
import mergeRefs from "merge-refs";
import React, { useRef, useState, useEffect } from "react";

export const withFloating = (Field) => {
  const WithFloating = React.forwardRef((props, ref) => {
    const inputRef = useRef();
    const [isPlaceholderShown, setIsPlaceholderShown] = useState(true);

    const checkPlaceholder = () => {
      const focused = document.activeElement;
      const input = inputRef.current;
      const value = input.value;
      const isFilled = typeof value !== "undefined" && value !== null && value !== "";
      const isFocused = focused === input;
      setIsPlaceholderShown(!isFocused && !isFilled);
    };

    useEffect(checkPlaceholder, []);

    const realPlaceholder = props.placeholder;

    const fieldWrapperClasses = [];
    const inputWrapperClasses = [];
    const labelWrapperClasses = [];

    fieldWrapperClasses.push("field-fl");

    if (isPlaceholderShown) {
      fieldWrapperClasses.push("field--placeholder");
      labelWrapperClasses.push("field--placeholder__label");
      inputWrapperClasses.push("field--placeholder__input");
    }

    const fieldProps = mergeProps(props, {
      ref: mergeRefs(ref, inputRef),
      onChange: checkPlaceholder,
      onFocus: checkPlaceholder,
      onBlur: checkPlaceholder,
      onKeyUp: checkPlaceholder,
    });

    fieldProps.fieldWrapperClasses = fieldWrapperClasses;
    fieldProps.labelWrapperClasses = labelWrapperClasses;
    fieldProps.inputWrapperClasses = inputWrapperClasses;
    fieldProps.placeholder = !isPlaceholderShown ? realPlaceholder : "";

    return <Field {...fieldProps} />;
  });

  WithFloating.displayName = `WithFloating(${Field.displayName})`;

  return WithFloating;
};
