import React from "react";

const stringifyAttribute = (value) => {
  if(typeof value === "boolean") {
    return value ? "true" : "false";
  }

  return value; 
};

export const InputText = React.forwardRef((props, ref) => {
  const { name, loading, success } = props;

  return <input
            type="text"
            name={name}
            id={name}
            {...props}
            ref={ref}
            loading={stringifyAttribute(loading)}
            success={stringifyAttribute(success)} />;
});

InputText.displayName = "InputText";
