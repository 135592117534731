import classnames from "classnames";
import React from "react";

import { InputText } from "../input/InputText";
import { Tooltip } from "src/common/components/Tooltip/Tooltip";

import InfoSVG from "src/common/assets/svg/info.svg";
import LoaderSVG from "src/common/assets/svg/loader.svg";
import CheckCircleSVG from "src/content/svg/check-circle.svg";

const FieldText = React.forwardRef((props, ref) => {
  const {
    name,
    label,
    required,
    description,
    descriptionTooltip,
    errors,
    size,
    loading,
    success,
    group,
    groupBeforeComponent,
    groupAfterComponent,
    fieldWrapperClasses: propFieldWrapperClasses,
    inputWrapperClasses: propInputWrapperClasses,
    labelWrapperClasses: propLabelWrapperClasses,
    labelRequiredIndicatorClasses: propLabelRequiredIndicatorClasses,
    errorWrapperClasses: propErrorWrapperClasses,
    descriptionWrapperClasses: propDescriptionWrapperClasses,
    inputComponent,
  } = props;
  const inputProps = Object.assign({}, props);
  const InputComponent = inputComponent;

  delete inputProps.description;
  delete inputProps.descriptionTooltip;
  delete inputProps.fieldWrapperClasses;
  delete inputProps.inputWrapperClasses;
  delete inputProps.labelWrapperClasses;
  delete inputProps.labelRequiredIndicatorClasses;
  delete inputProps.descriptionWrapperClasses;
  delete inputProps.errorWrapperClasses;
  delete inputProps.inputComponent;
  delete inputProps.group;
  delete inputProps.groupBeforeComponent;
  delete inputProps.groupAfterComponent;

  let fieldWrapperClasses = [...propFieldWrapperClasses];
  let inputWrapperClasses = [...propInputWrapperClasses];
  let labelWrapperClasses = [...propLabelWrapperClasses];
  let labelRequiredIndicatorClasses = [...propLabelRequiredIndicatorClasses];
  let errorWrapperClasses = [...propErrorWrapperClasses];
  let descriptionWrapperClasses = [...propDescriptionWrapperClasses];

  if (propFieldWrapperClasses !== FieldText.defaultProps.fieldWrapperClasses) {
    fieldWrapperClasses = FieldText.defaultProps.fieldWrapperClasses.concat(propFieldWrapperClasses);
  }

  if (propInputWrapperClasses !== FieldText.defaultProps.inputWrapperClasses) {
    inputWrapperClasses = FieldText.defaultProps.inputWrapperClasses.concat(propInputWrapperClasses);
  }

  if (propLabelWrapperClasses !== FieldText.defaultProps.labelWrapperClasses) {
    labelWrapperClasses = FieldText.defaultProps.labelWrapperClasses.concat(propLabelWrapperClasses);
  }

  if (propLabelRequiredIndicatorClasses !== FieldText.defaultProps.labelRequiredIndicatorClasses) {
    labelRequiredIndicatorClasses = FieldText.defaultProps.labelRequiredIndicatorClasses.concat(
      propLabelRequiredIndicatorClasses,
    );
  }

  if (propErrorWrapperClasses !== FieldText.defaultProps.errorWrapperClasses) {
    errorWrapperClasses = FieldText.defaultProps.errorWrapperClasses.concat(propErrorWrapperClasses);
  }

  if (size && (size === "lg" || size === "sm")) {
    fieldWrapperClasses.push(`field-${size}`);
    inputWrapperClasses.push(`field--${size}__input`);
    labelWrapperClasses.push(`field--${size}__label`);
    labelRequiredIndicatorClasses.push(`field--${size}__reqin`);
  }

  if (group) {
    fieldWrapperClasses.push(`field-group`);
    inputWrapperClasses.push(`field--group__input`);
    labelWrapperClasses.push(`field--group__label`);
  }

  if (errors) {
    fieldWrapperClasses.push(`field--errors`);
    inputWrapperClasses.push(`field--errors__input`);
    labelWrapperClasses.push(`field--errors__label`);
  }

  if (loading) {
    fieldWrapperClasses.push(`field--loading`);
    inputWrapperClasses.push(`field--loading__input`);
    labelWrapperClasses.push(`field--loading__label`);
  }

  if (success) {
    fieldWrapperClasses.push(`field--success`);
    inputWrapperClasses.push(`field--success__input`);
    labelWrapperClasses.push(`field--success__label`);
  }

  const BeforeComponent = groupBeforeComponent;
  const AfterComponent = groupAfterComponent;

  return (
    <div className={classnames(fieldWrapperClasses)} data-test-field={name}>
      <div className={classnames(labelWrapperClasses)}>
        {label && (
          <label htmlFor={name} data-test-field-label={name}>
            {label}
            {required && <em className={classnames(labelRequiredIndicatorClasses)}>*</em>}
          </label>
        )}
      </div>
      <div className={classnames(inputWrapperClasses)}>
        {BeforeComponent && <BeforeComponent />}
        <InputComponent ref={ref} {...inputProps} data-test-field-input={name} />
        {loading && (
          <div className="field__loader">
            <LoaderSVG />
          </div>
        )}
        {success && (
          <div className="field__success">
            <CheckCircleSVG />
          </div>
        )}
        {AfterComponent && <AfterComponent />}
      </div>
      {errors && (
        <div className={classnames(errorWrapperClasses)} data-test-field-error={name}>
          {errors?.type === "required" && <p>To pole jest wymagane i nie może być puste</p>}
          {errors?.type !== "required" && <p dangerouslySetInnerHTML={{ __html: errors?.message }} />}
        </div>
      )}
      {description && descriptionTooltip && (
        <div className={classnames(descriptionWrapperClasses)}>
          {descriptionTooltip && (
            <Tooltip
              trigger={
                <>
                  <InfoSVG className="mt-n2px me-2" width="16" height="16" fill="#4C5F6B" />
                </>
              }
              description={<>{description}</>}
            >
              {descriptionTooltip}
            </Tooltip>
          )}
          {}
        </div>
      )}
      {description && !descriptionTooltip && <div className={classnames(descriptionWrapperClasses)}>{description}</div>}
    </div>
  );
});

FieldText.displayName = "FieldText";

FieldText.defaultProps = {
  required: false,
  size: "",
  description: "",
  descriptionTooltip: "",
  group: false,
  loading: false,
  success: false,
  groupBeforeComponent: null,
  groupAfterComponent: null,
  fieldWrapperClasses: ["field", "field--text"],
  labelWrapperClasses: ["field__label", "field--text__label"],
  labelRequiredIndicatorClasses: ["field__reqin", "field--text__reqin"],
  inputWrapperClasses: ["field__input", "field--text__input"],
  descriptionWrapperClasses: ["field__description", "field--text__description"],
  errorWrapperClasses: ["field__errors", "field--text__errors"],
  inputComponent: InputText,
};

export { FieldText };
